import Helmet from "react-helmet"
import React from "react"
import Styles from "./css/expressload.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import JSONData from "./data/express.json"
import SimpDisclosure from "src/components/simpdisclosure"

class Expressload extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: JSONData, numbers: Array.from(Array(101).keys())});
  }
  componentDidMount(){
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if(this.props.data){
      this.props.data.then(data => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData});
        this.headline(this.state.data);
      });
    }
    else{
      this.headline(this.state.data);
    }
  }
  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }
  render() {
    return (
        <div className = {Styles.expressload}  style = {{display: this.state.display}}>
<div className={Styles.expressWrap}>
  <div className={Styles.modal}>
    <div className={Styles.wrapper}>
      <div className={Styles.hold}>
        <div className={`${Styles.pie} ${Styles.na}`}></div>
        <div className={`${Styles.pie} ${Styles.fill}`}></div>
      </div>

    <div className={`${Styles.hold} ${Styles.gt2}`}>
      <div className={`${Styles.pie} ${Styles.na}`}></div>
    </div>

    <div className={Styles.mask}>
      <p>{this.state.data.copyOne}</p>
      <div className={Styles.cell}>
        <ul>
          {this.state.numbers != undefined ? this.state.numbers.map((number, i) => {
              return <li key={i}>{number}</li>
            }) : null}
        </ul>

      </div>
    </div>
    <div className={`${Styles.mask} ${Styles.clear}`}>{this.state.data.copyTwo}
      <img src={this.state.data.checkImg} className={Styles.check} />
    </div>
    </div>
      <p className={Styles.dl}>{this.state.data.subhead}</p>
    <button className={Styles.dlModal} data-cy="cta" onClick = {() => window.triggerInstall()} dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></button>
</div>
<SimpDisclosure></SimpDisclosure>
    </div>
        </div>
    )
  }
}
export default Expressload
