import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Expressload from "src/components/expressload"
import Btf2col from "src/components/btf2col"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const expressData = import("./data/data.json");

const expressBrowsers = ['chrome','ie','edge','firefox','other'];

export default function PrdExpressload() {

  let css;
    if(isFirefox()){
      css = `
     body {
       
     }
      `;
    }
    else{
      css = ``;
    }

  let module;
  let footermodule;
  module = null;
  footermodule = null;
  if(isChrome() || isIeEdge() || isFirefox()){
    module = <Expressload data={expressData} browsers={expressBrowsers}></Expressload>;
    footermodule = <Btf2col></Btf2col>;
  }
  return(
    <HomepageLayout>
    <div>
      <Helmet>
      <style type="text/css">{`

        .trbm-module--trbm button {
          border: none;
           font-size: 35px;
           font-weight: normal;
           border-radius: 5px;
           margin: 0px auto 5px;
           cursor: pointer;
           color: white;
           padding: 12px!important;
           width: 330px;
           background: #00c506;
        }

        .trbm-module--trbm .trbm-module--steps {
          display: none;
        }

        .trbm-module--trbm .trbm-module--bullets li {
          font-weight: normal;
          font-size: 12px !important;
          color: #666;
          padding-bottom: 10px;
        }

        .trbm-module--trbm h2 {
          font-weight: normal;
           font-size: 18px;
           margin: 15px 0px;
           padding-bottom: 0px;
        }

        .trbm-module--trbm h1 {
          margin: 0 auto;
          width: auto;
        }

        .trbm-module--trbm .trbm-module--card {
          margin: 100px auto 0px;
        }

        .footerlinks-module--br, .footerlinks-module--br a {
          color: #ccc;
        }

        #ctadisclosuresearch-module--ctadisclaimersearch {
          color:#fff;
          width:42%;
          padding: 40px 7% 10px;
        )

        `}
        </style>
        <style type="text/css">
        {css}
            </style>
        <title>Edit PDFS for Free! - frompdftodoc.com</title>
      </Helmet>
      {module}

    </div>

    {footermodule}

    </HomepageLayout>
  )
}