import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/simp.module.scss"

class SimpDisclosure extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    return (
        <div id = {Styles.simpdisclosure}>
        <div className={Styles.disclosure}>
        <p>By clicking Continue, you agree to the <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Terms of Service</a> and <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Privacy Policy</a>.</p>
          </div>
        </div>
    )
  }
}
export default SimpDisclosure
