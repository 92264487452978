import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/btf2col.module.scss"
import Data from "./data/btf2col.json"
import FooterlinksSingle from "src/components/footerlinksNotAbsPos/footer.js"


class btf2col extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isChrome: isChrome(),
        isFirefox: isFirefox(),
        isIeEdge: isIeEdge(),
        display: 'none',
        pageData: props.data,
        data: Data
      }
    }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    if(this.props.data){
      this.props.data.then(data => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }

      });
    }
  }
  render() {
    return (

      <div id = {Styles.btf2col} className = {Styles.bottom}>
       <h2 className = {Styles.headline}>{this.state.data.btfHeadline}</h2>

        <div className = {Styles.midInner}>
            <div className = {Styles.left}>
              <div className = {Styles.howto}>
                <div className = {Styles.arrow1}></div><span dangerouslySetInnerHTML={{ __html: this.state.data.step1}}></span>
                <div className = {Styles.arrow2}></div><span dangerouslySetInnerHTML={{ __html: this.state.data.step2}}></span>
                <div className = {Styles.arrow3}></div><span dangerouslySetInnerHTML={{ __html: this.state.data.step3}}></span>
              </div>
            </div>

            <div className = {Styles.right}>
              <img className = {Styles.bam} src={this.state.data.prodImg} />
            </div>
        </div>
        <FooterlinksSingle></FooterlinksSingle>
      </div>

    )
  }
}
export default btf2col
